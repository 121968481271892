import React from 'react';
import { createContext, ReactNode } from 'react';
import Client, { BaseURL } from '../utils/quiqup-platform/client';
import { Auth } from '@quiqupltd/quiqupjs';

export interface QuiqupPlatformContextType {
  client: Client;
}

export const QuiqupPlatformContext = createContext<QuiqupPlatformContextType | undefined>(undefined);

interface AppProviderProps {
  children: ReactNode;
}

export function QuiqupPlatformContextProvider({ children }: AppProviderProps): JSX.Element {
  const baseURL: BaseURL = window.env.PLATFORM_API;
  const client = new Client(baseURL, {
    auth: (): string | Promise<string | undefined> => (Auth.hasToken() ? Auth.hasToken().auth : ''),
  });

  return <QuiqupPlatformContext.Provider value={{ client }}>{children}</QuiqupPlatformContext.Provider>;
}
