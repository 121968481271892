import userActionTypes from './user.action-types.js';
import { Auth, Partner } from '@quiqupltd/quiqupjs';
import * as Sentry from '@sentry/react';
import { identify } from '../../../globals/analytics';
import { client as GraphQLClient } from '../../../containers/address-book/address-book-client';
import { isDevelopment } from '../../../environment';

export const authUser =
  (email, password) =>
  ({ dispatch }) => {
    return Auth.login(email, password)
      .then(
        (data) => dispatch(fetchLoginSuccess(data)),
        (error) => dispatch(fetchLoginError(error))
      )
      .then((data) => dispatch(fetchUser(data)));
  };

export const identifyUserWithSentry = (user) => {
  if (!isDevelopment) {
    identify(user.id, {
      id: user.id,
      email: user.email,
      name: user.fullname,
    });

    Sentry.setUser({
      id: user.id,
      username: user.fullname,
      email: user.email,
    });
  }
};

export function onboardingCompleted() {
  return { type: userActionTypes.ONBOARDING_COMPLETE };
}

export function fetchLoginStart(payload) {
  return { type: userActionTypes.LOGIN_STARTED, payload };
}

export const fetchLogin =
  ({ email, password }) =>
  ({ dispatch }) => {
    dispatch(fetchLoginStart({ email, password }));
    return dispatch(authUser(email, password));
  };

export const fetchRegion =
  (regionCode) =>
  ({ dispatch }) => {
    return Partner.regions.get({ regionCode }).then((region) => {
      dispatch({
        type: userActionTypes.FETCH_USER_REGION_SUCCESS,
        payload: region,
      });
    });
  };

export const createAccount =
  (payload) =>
  ({ dispatch }) => {
    const partnerPayload = {
      regionCode: payload.regionCode,
      firstname: payload.firstname,
      lastname: payload.lastname,
      email: payload.email,
      password: payload.password,
      passwordConfirmation: payload.passwordConfirmation,
      businessPhone: payload.businessPhone,
      companyName: payload.company,
      volume: payload.volume,
      paymentMode: payload.paymentMode,
      partnerSignup: payload.partnerSignup,
      partnerUpdateNewsletter: payload.partnerUpdateNewsletter,
      thoughLeadershipNewsletter: payload.thoughLeadershipNewsletter,
    };
    return Partner.user.create(partnerPayload).then(() => {
      dispatch({ type: userActionTypes.USER_CREATED });

      return dispatch(authUser(payload.email, payload.password)).then((user) => {
        return dispatch(fetchRegion(payload.regionCode)).then(() => user);
      });
    });
  };

export function fetchLoginSuccess(payload) {
  return { type: userActionTypes.FETCH_LOGIN_SUCCESS, payload };
}

export function fetchLoginError(payload) {
  return { type: userActionTypes.FETCH_LOGIN_FAILED, payload };
}

export function logout(cb = () => null) {
  Auth.removeToken();
  cb();
  GraphQLClient().clearStore();
  sessionStorage.clear();
  return { type: userActionTypes.LOGOUT };
}

export function fetchUserStart(payload) {
  return { type: userActionTypes.FETCH_USER_STARTED, payload };
}

export function fetchUser(payload) {
  return ({ dispatch }) => {
    dispatch(fetchUserStart(payload));
    return Partner.user
      .get()
      .then((data) => {
        identifyUserWithSentry(data);
        dispatch(fetchUsersSuccess(data));
        return data;
      })
      .catch((error) => Promise.reject(dispatch(fetchUsersError(error))));
  };
}

export function updateUser(payload) {
  return {
    type: userActionTypes.UPDATE_USER,
    promise: Partner.user.update.bind(null, payload),
  };
}

export function fetchUsersSuccess(payload) {
  return { type: userActionTypes.FETCH_USERS_SUCCESS, payload: { ...payload, id: Number(payload.id) } };
}

export function fetchUsersError(payload) {
  return { type: userActionTypes.FETCH_USERS_FAILED, payload };
}
