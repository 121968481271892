// Code generated by the Encore v1.46.7 client generator. DO NOT EDIT.

// Disable eslint, jshint, and jslint for this file.
/* eslint-disable */
/* jshint ignore:start */
/*jslint-disable*/

/**
 * BaseURL is the base URL for calling the Encore application's API.
 */
export type BaseURL = string;

export const Local: BaseURL = 'http://localhost:4000';

/**
 * Environment returns a BaseURL for calling the cloud environment with the given name.
 */
export function Environment(name: string): BaseURL {
  return `https://${name}-quiqup-platform-ht42.encr.app`;
}

/**
 * PreviewEnv returns a BaseURL for calling the preview environment with the given PR number.
 */
export function PreviewEnv(pr: number | string): BaseURL {
  return Environment(`pr${pr}`);
}

/**
 * Client is an API client for the quiqup-platform-ht42 Encore application.
 */
export default class Client {
  public readonly accounts_api: accounts_api.ServiceClient;

  /**
   * @deprecated This constructor is deprecated, and you should move to using BaseURL with an Options object
   */
  constructor(target: string, token?: string);

  /**
   * Creates a Client for calling the public and authenticated APIs of your Encore application.
   *
   * @param target  The target which the client should be configured to use. See Local and Environment for options.
   * @param options Options for the client
   */
  constructor(target: BaseURL, options?: ClientOptions);
  constructor(target: string | BaseURL = 'prod', options?: string | ClientOptions) {
    // Convert the old constructor parameters to a BaseURL object and a ClientOptions object
    if (!target.startsWith('http://') && !target.startsWith('https://')) {
      target = Environment(target);
    }

    if (typeof options === 'string') {
      options = { auth: options };
    }

    const base = new BaseClient(target, options ?? {});
    this.accounts_api = new accounts_api.ServiceClient(base);
  }
}

/**
 * ClientOptions allows you to override any default behaviour within the generated Encore client.
 */
export interface ClientOptions {
  /**
   * By default the client will use the inbuilt fetch function for making the API requests.
   * however you can override it with your own implementation here if you want to run custom
   * code on each API request made or response received.
   */
  fetcher?: Fetcher;

  /** Default RequestInit to be used for the client */
  requestInit?: Omit<RequestInit, 'headers'> & { headers?: Record<string, string> };

  /**
   * Allows you to set the auth token to be used for each request
   * either by passing in a static token string or by passing in a function
   * which returns the auth token.
   *
   * These tokens will be sent as bearer tokens in the Authorization header.
   */
  auth?: string | AuthDataGenerator;
}

export namespace accounts_api {
  export interface AddressListResponse {
    addresses: AddressResponse[];
  }

  export interface AddressResponse {
    id: string;
    is_deleted: boolean;
    name: string;
    currency_iso_code: string;
    created_date: string;
    created_by_id: string;
    last_modified_date: string;
    last_modified_by_id: string;
    system_modstamp: string;
    last_viewed_date: string;
    last_referenced_date: string;
    account: string;
    address_line1: string;
    address_line2: string;
    auto_fill: boolean;
    coordinates: string;
    country: string;
    instructions_notes: string;
    latitude: number;
    long_location_name: string;
    longitude: number;
    phone_number: string;
    region: string;
    town: string;
    pickup_zone: string;
  }

  export interface BlockUpdateRequest {
    blocked: boolean;
  }

  export interface BusinessAccountListRequest {
    limit?: number;
    page?: number;
    sort?: string;
    sort_desc?: boolean;
    email_like?: string;
    external_id?: string;
    customer_code?: string;
    warehouse_code?: string;
    updated_after?: string;
  }

  export interface BusinessAccountListResponse {
    accounts: BusinessAccountResponse[];
    pagination: pagination.PaginationMeta;
  }

  export interface BusinessAccountResponse {
    id: string;
    is_deleted: boolean;
    master_record_id: string;
    name: string;
    type: string;
    record_type_id: string;
    parent_id: string;
    billing_street: string;
    billing_city: string;
    billing_state: string;
    billing_postal_code: string;
    billing_country: string;
    billing_latitude: number;
    billing_longitude: number;
    billing_geocode_accuracy: string;
    shipping_street: string;
    shipping_city: string;
    shipping_state: string;
    shipping_postal_code: string;
    shipping_country: string;
    shipping_latitude: number;
    shipping_longitude: number;
    shipping_geocode_accuracy: string;
    fax: string;
    account_number: string;
    website: string;
    photo_url: string;
    sic: string;
    industry: string;
    annual_revenue: number;
    number_of_employees: number;
    ticker_symbol: string;
    description: string;
    currency_iso_code: string;
    owner_id: string;
    created_date: string;
    created_by_id: string;
    last_modified_date: string;
    last_modified_by_id: string;
    system_modstamp: string;
    last_activity_date: string;
    last_viewed_date: string;
    last_referenced_date: string;
    is_customer_portal: boolean;
    jigsaw: string;
    jigsaw_company_id: string;
    account_source: string;
    sic_desc: string;
    is_priority_record: boolean;
    address1: string;
    address2: string;
    contact_cs_escalation: string;
    ops_contact: string;
    is_this_account_integrated: string;
    salesforce_id18digits: string;
    number_of_business_accounts: number;
    city: string;
    client_priority: string;
    business_account_email: string;
    linehaul_timing: string;
    country: string;
    same_day_other_emirates_cut_off_time: string;
    key_account: boolean;
    require_customer_identification: boolean;
    require_in_person_delivery: boolean;
    email: string;
    external_id: string;
    quiqup_finance_poc: string;
    financials_complete: boolean;
    first_name: string;
    service_offering: string;
    unique_name: string;
    delivery_failed_sms: boolean;
    grandparent_account: string;
    next_day_other_emirates_cut_off_time: string;
    count: number;
    typeform_typeform_form_mapping: string;
    delivery_complete: boolean;
    delivery_complete_placeholder: string;
    delivery_failed_placeholder_message: string;
    x4hr_cut_off_time: string;
    poc_contact_id2: string;
    next_day_cut_off_time: string;
    grandparent_account_name: string;
    last_name: string;
    same_day_cut_off_time: string;
    mobile: string;
    ecommerce_delivery_options: string;
    referral_method: string;
    account_stage: string;
    reason_for_account_lost: string;
    utm_campaign: string;
    synety_call_phone: string;
    utm_content: string;
    post_code: string;
    email_addresses_for_invoicing: string;
    utm_medium: string;
    other_vertical: string;
    linehaul_method: string;
    utm_source: string;
    utm_term: string;
    account_status: string;
    segment_anonymous_id_field: string;
    opportunity_bdm: string;
    new_client_timestamp: string;
    onboarding_timestamp: string;
    integration_timestamp: string;
    go_live_timestamp: string;
    nurturing_timestamp: string;
    handover_timestamp: string;
    is_parent: boolean;
    can_create_same_day: boolean;
    lead_source_f: string;
    client_classification: string;
    last_order_delivered: string;
    em_post_vertical: string;
    login_sent: boolean;
    client_type: string;
    reason_for_disqualification: string;
    has_trade_license: string;
    pending_reason: string;
    settle_cod_separately: boolean;
    status_if_active: string;
    payment_terms: number;
    expected_number_of_orders: string;
    billing_contact_email: string;
    fpm: string;
    no_docs1st: string;
    x3d_no_email_sent: boolean;
    activated_timestamp: string;
    first_order_delivered: string;
    x7d_no_email_sent: boolean;
    invoice_monthly: boolean;
    signed_terms: string;
    fullname: string;
    business_account_type: string;
    qd_password: string;
    pay_without_confirmation: boolean;
    anchanto_client_id: string;
    anchanto_client_secret: string;
    anchanto_password: string;
    emirates_post_fee: boolean;
    anchanto_username: string;
    trade_license_name: string;
    export_client: boolean;
    no_docs7th: string;
    no_docs14th: string;
    quick_notes: string;
    block_order_submission: boolean;
    automatic_service_interruption: boolean;
    partner: string;
    all_time_order_count: number;
    enable_otp: boolean;
    last4weeks_order_count: number;
    anchanto_customer_code: string;
    all_time_order: number;
    sales_ff_setup_completed: boolean;
    dhl_incoterm: string;
    related_bs_account: string;
    does_fulfillment: boolean;
    backend_id_billing_identifier: string;
    billing_address: string;
    wms_ff_setup_completed: boolean;
    login_info_sent: string;
    parcel_track_sms: boolean;
    anchanto_warehouse_code: string;
    carrier_account: string;
    must_have_bank_card: boolean;
    placeholder_live_message: string;
    placeholder_message: string;
    created_date_formula: string;
    account_type: string;
    region: string;
    related_bsaccount_nodashes: string;
    name_of_parent_account: string;
    last_active_from: string;
    pay_at_pickup: boolean;
    invoice_frequency: string;
    invoicing_email: string;
    billing_contact_name1: string;
    long_id: string;
    configured_on_b2b_invoicer_by_new: string;
    quiqdash_self_signup: boolean;
    child_zone: string;
    lead_record_type: string;
    single_drop_dubai: number;
    bank_name: string;
    bank_account_name: string;
    iban_number: string;
    swift_code: string;
    trn_number: string;
    billing_contact1name: string;
    billing_contact2name: string;
    billing_contact1email: string;
    billing_contact2email: string;
    billing_contact1contact_number: number;
    billing_contact2contact_number: number;
    associated_opportunity: string;
    record_type_id_c: string;
    billing_street_c: string;
    phone_c: string;
    source: string;
    vertical: string;
    go_live_date: string;
    customer_segmentation: string;
    default_job_kind: string;
    logo_url: string;
    multi_drop_allowed: boolean;
    multi_pick_allowed: boolean;
    payment_modes_available: boolean;
    pooling_allowed: boolean;
    pricing_model: string;
    sms_header: string;
    sms_tracking_by_default: boolean;
    support_phone_number: string;
    temp_password: string;
    contact_reference: string;
    invoice_to_parent: boolean;
    billing_bank_name: string;
    allow_carrier_cod: boolean;
    Cross_Border_Terms_Signed__c: string;
    ships_dangerous_goods: boolean;
  }

  export interface CreateAddressRequest {
    name: string;
    address_line1: string;
    region: string;
    pickup_zone: string;
    phone_number: string;
    account: string;
    currency_iso_code: string;
    address_line2: string;
    auto_fill: boolean;
    coordinates: string;
    country: string;
    instructions_notes: string;
    latitude: number;
    long_location_name: string;
    longitude: number;
    town: string;
  }

  export interface CreateResponse {
    id: string;
  }

  export interface PartnerAddressListResponse {
    addresses: PartnerAddressResponse[];
  }

  export interface PartnerAddressResponse {
    id: string;
    external_id: string;
    name: string;
    long_location_name: string;
    phone_number: string;
    address_line1: string;
    address_line2: string;
    town: string;
    region: string;
    country: string;
    pickup_zone: string;
    coordinates: datatypes.Coordinate;
    instructions_notes: string;
    auto_fill: boolean;
  }

  export interface PartnerCreateAddressRequest {
    /**
     * Name is the name of the address for saving
     */
    name: string;

    /**
     * LongLocationName is the long location name of the address
     */
    long_location_name?: string;

    /**
     * PhoneNumber is the phone number of the address
     */
    phone_number: string;

    /**
     * AddressLine1 is the first line of the address
     */
    address_line1: string;

    /**
     * 	AddressLine2 is the second line of the address
     */
    address_line2?: string;

    /**
     * Region is the region for the address
     */
    region: string;

    /**
     * PickupZone is the pickup zone for the address
     */
    pickup_zone: string;

    /**
     * Coordinates are the coordinates for the address
     */
    coordinates?: datatypes.Coordinate;

    /**
     * InstructionsNotes are the instructions for the address
     */
    instructions_notes?: string;

    /**
     * AutoFill is whether to auto fill the address (is it default)
     */
    auto_fill: boolean;
  }

  export interface PartnerUpdateAddressRequest {
    name?: string;
    long_location_name?: string;
    address_line1?: string;
    address_line2?: string;
    phone_number?: string;
    coordinates?: datatypes.Coordinate;
    region?: string;
    pickup_zone?: string;
    instructions_notes?: string;
  }

  export interface PricingModelResponse {
    id: string;
    is_deleted: boolean;
    name: string;
    currency_iso_code: string;
    record_type_id: string;
    created_date: string;
    created_by_id: string;
    last_modified_date: string;
    last_modified_by_id: string;
    system_modstamp: string;
    last_viewed_date: string;
    last_referenced_date: string;
    account: string;
    fixed_card_fee: number;
    active_from: string;
    fixed_cash_fee: number;
    count_cutoff_pricing: number;
    count_cutoff_pricing_new: number;
    additional_waypoint: number;
    unique_active_from: string;
    max_kg_per_parcel: number;
    per_extra_kg_fee: number;
    remote_area_fee: number;
    price_per_additional_item: number;
    charge_for_rto: boolean;
    charge_per_extra_km: number;
    charge1: number;
    applies_after_no_of_orders: number;
    billing_period: string;
    card_handling_fee: number;
    cash_handling_fee: number;
    discount_in_aed: number;
    last_modified_date_time: string;
    extended: number;
    charge2: number;
    flat_price_per_order: number;
    name_c: string;
    out_of_zone: number;
    pickup: number;
    pricing_type: string;
    primary: number;
    secondary: number;
    subscription_cost: number;
    subscription_name: string;
    x4hours_from_emirates: number;
    x4hours_other_emirates: number;
    same_day_from_emirates: number;
    same_day_other_emirates: number;
    next_day_from_emirates: number;
    x1st_drop: number;
    x2nd_drop: number;
    x3rd_drop: number;
    next_day_other_emirates: number;
    returns_from_emirates: number;
    returns_other_emirates: number;
    fulfilment_from_emirates: number;
    fulfilment_other_emirates: number;
    store_from_emirates: number;
    store_other_emirates: number;
    charge3: number;
    charge4: number;
    charge5: number;
    range1: number;
    range2: number;
    range3: number;
    range4: number;
    range5: number;
    bulk_load_collections_from_auh_shj: number;
    x74x74x80cm_up_to10kg: number;
    bubble_wrapping_if_required: number;
    minimum_monthly_charge: number;
    bulk_load_collections_from_dubai: number;
    ambient_storage_per_cbm_month: number;
    bubble_wrap: boolean;
    x53x53x53cm_up_to5kg: number;
    x60x60x60cm_up_to7kg: number;
    each_extra_piece_order_above5pieces: number;
    pick_pack_track_next_day: number;
    return_reverse_service_per_order: number;
    return_reverse_remote_areas_per_order: number;
    receiving_stock_per_piece: number;
    pick_pack_track4hours: number;
    pick_pack_track_same_day: number;
    pick_pack_track4hour_other_emirate: number;
    pick_pack_track_next_day_other: number;
    pick_pack_track_same_day_other: number;
    return_reverse_service_per_order_other: number;
    credit_notes_payment_period: string;
    barcoding_per_piece: number;
    pick_pack_track_international: number;
    dry_storage_per_cbm_month: number;
    handling_out_per_piece: number;
    truck_load_collection_from_outside_dub: number;
    truck_load_collection_dubai: number;
    number_of_free_pieces_per_order: number;
    special_packaging: boolean;
    small_carton: number;
    special_packaging_price: number;
    medium_carton: number;
    pick_pack_per_item: number;
    labeling_per_item: number;
    price_per_box_b2: number;
    min_delivery_charge: number;
    additional_box_charge: number;
    ambient_minimum: number;
    dhl_markup: number;
    dhl_epg: number;
    dhl_fuel_surcharge: number;
    aramex_markup: number;
    aramex_fuel_surcharge: number;
    aramex_epg: number;
  }

  export interface Response {
    message: string;
  }

  export interface UpdateBusinessAccountRequest {
    export_client: boolean;
    cross_border_terms_signed: string;
    carrier_account: string;
    dhl_incoterm: string;
    bank_name: string;
    bank_account_name: string;
    iban_number: string;
  }

  export class ServiceClient {
    private baseClient: BaseClient;

    constructor(baseClient: BaseClient) {
      this.baseClient = baseClient;
    }

    /**
     * CreatePickupAddress creates a new pickup address
     *
     * This is the current endpoint for creating a pickup address, but we will be replacing it with a new endpoint, with the correct
     * and start to use a workflow
     *
     * Deprecated: This endpoint will be removed in a later pr, use the new /partner endpoint instead
     */
    public async CreatePickupAddress(params: CreateAddressRequest): Promise<CreateResponse> {
      // Now make the actual call to the API
      const resp = await this.baseClient.callTypedAPI('POST', `/addresses`, JSON.stringify(params));
      return (await resp.json()) as CreateResponse;
    }

    public async GetAccount(): Promise<BusinessAccountResponse> {
      // Now make the actual call to the API
      const resp = await this.baseClient.callTypedAPI('GET', `/account`);
      return (await resp.json()) as BusinessAccountResponse;
    }

    public async GetAccountById(id: string): Promise<BusinessAccountResponse> {
      // Now make the actual call to the API
      const resp = await this.baseClient.callTypedAPI('GET', `/accounts/${encodeURIComponent(id)}`);
      return (await resp.json()) as BusinessAccountResponse;
    }

    public async GetAccountPricingModel(id: string): Promise<PricingModelResponse> {
      // Now make the actual call to the API
      const resp = await this.baseClient.callTypedAPI('GET', `/accounts/${encodeURIComponent(id)}/pricing-model`);
      return (await resp.json()) as PricingModelResponse;
    }

    /**
     * GetAddressById gets a pickup address by id
     *
     * Deprecated: This endpoint will be removed in a later pr, use the new /partner endpoint instead
     */
    public async GetAddressById(addressId: string): Promise<AddressResponse> {
      // Now make the actual call to the API
      const resp = await this.baseClient.callTypedAPI('GET', `/addresses/${encodeURIComponent(addressId)}`);
      return (await resp.json()) as AddressResponse;
    }

    public async ListAccounts(params: BusinessAccountListRequest): Promise<BusinessAccountListResponse> {
      // Convert our params into the objects we need for the request
      const query = makeRecord<string, string | string[]>({
        customer_code: params['customer_code'],
        email_match: params['email_like'],
        external_id: params['external_id'],
        limit: params.limit === undefined ? undefined : String(params.limit),
        page: params.page === undefined ? undefined : String(params.page),
        sort: params.sort,
        sort_desc: params['sort_desc'] === undefined ? undefined : String(params['sort_desc']),
        updated_after: params['updated_after'] === undefined ? undefined : String(params['updated_after']),
        warehouse_code: params['warehouse_code'],
      });

      // Now make the actual call to the API
      const resp = await this.baseClient.callTypedAPI('GET', `/accounts`, undefined, { query });
      return (await resp.json()) as BusinessAccountListResponse;
    }

    /**
     * ListAddresses lists all the pickup addresses for an account
     *
     * Deprecated: This endpoint will be removed in a later pr, use the new /partner endpoint instead
     */
    public async ListAddresses(id: string): Promise<AddressListResponse> {
      // Now make the actual call to the API
      const resp = await this.baseClient.callTypedAPI('GET', `/accounts/${encodeURIComponent(id)}/addresses`);
      return (await resp.json()) as AddressListResponse;
    }

    /**
     * PartnerCreateAddress creates a new address for a partner
     *
     * Create a new address for a partner, this will create in salesforce also
     * on production environment
     */
    public async PartnerCreateAddress(params: PartnerCreateAddressRequest): Promise<PartnerAddressResponse> {
      // Now make the actual call to the API
      const resp = await this.baseClient.callTypedAPI('POST', `/partner/addresses`, JSON.stringify(params));
      return (await resp.json()) as PartnerAddressResponse;
    }

    /**
     * GetAddressById gets a pickup address by id
     */
    public async PartnerGetAddressById(addressId: string): Promise<PartnerAddressResponse> {
      // Now make the actual call to the API
      const resp = await this.baseClient.callTypedAPI('GET', `/partner/addresses/${encodeURIComponent(addressId)}`);
      return (await resp.json()) as PartnerAddressResponse;
    }

    /**
     * ListAddresses lists all the pickup addresses for an account
     */
    public async PartnerListAddresses(id: string): Promise<PartnerAddressListResponse> {
      // Now make the actual call to the API
      const resp = await this.baseClient.callTypedAPI('GET', `/partner/accounts/${encodeURIComponent(id)}/addresses`);
      return (await resp.json()) as PartnerAddressListResponse;
    }

    /**
     * PartnerUpdateAddress updates an address for a partner
     * in the local database and salesforce
     */
    public async PartnerUpdateAddress(
      id: string,
      params: PartnerUpdateAddressRequest
    ): Promise<PartnerAddressResponse> {
      // Now make the actual call to the API
      const resp = await this.baseClient.callTypedAPI(
        'PATCH',
        `/partner/addresses/${encodeURIComponent(id)}`,
        JSON.stringify(params)
      );
      return (await resp.json()) as PartnerAddressResponse;
    }

    public async SetBlockState(id: string, params: BlockUpdateRequest): Promise<void> {
      await this.baseClient.callTypedAPI(
        'POST',
        `/accounts/${encodeURIComponent(id)}/set-block-state`,
        JSON.stringify(params)
      );
    }

    public async UpdateBusinessAccount(params: UpdateBusinessAccountRequest): Promise<Response> {
      // Now make the actual call to the API
      const resp = await this.baseClient.callTypedAPI('PUT', `/accounts`, JSON.stringify(params));
      return (await resp.json()) as Response;
    }
  }
}

export namespace datatypes {
  export interface Coordinate {
    latitude: number;
    longitude: number;
  }
}

export namespace pagination {
  export interface PaginationMeta {
    next?: PaginationRequest;
    next_url?: string;
    prev?: PaginationRequest;
    prev_url?: string;
    total: number;
  }

  export interface PaginationRequest {
    limit: number;
    page: number;
    sort: string;
    sort_desc: boolean;
  }
}

function encodeQuery(parts: Record<string, string | string[]>): string {
  const pairs: string[] = [];
  for (const key in parts) {
    const val = (Array.isArray(parts[key]) ? parts[key] : [parts[key]]) as string[];
    for (const v of val) {
      pairs.push(`${key}=${encodeURIComponent(v)}`);
    }
  }
  return pairs.join('&');
}

// makeRecord takes a record and strips any undefined values from it,
// and returns the same record with a narrower type.
// @ts-ignore - TS ignore because makeRecord is not always used
function makeRecord<K extends string | number | symbol, V>(record: Record<K, V | undefined>): Record<K, V> {
  for (const key in record) {
    if (record[key] === undefined) {
      delete record[key];
    }
  }
  return record as Record<K, V>;
}

function encodeWebSocketHeaders(headers: Record<string, string>) {
  // url safe, no pad
  const base64encoded = btoa(JSON.stringify(headers)).replaceAll('=', '').replaceAll('+', '-').replaceAll('/', '_');
  return 'encore.dev.headers.' + base64encoded;
}

class WebSocketConnection {
  public ws: WebSocket;

  private hasUpdateHandlers: (() => void)[] = [];

  constructor(url: string, headers?: Record<string, string>) {
    let protocols = ['encore-ws'];
    if (headers) {
      protocols.push(encodeWebSocketHeaders(headers));
    }

    this.ws = new WebSocket(url, protocols);

    this.on('error', () => {
      this.resolveHasUpdateHandlers();
    });

    this.on('close', () => {
      this.resolveHasUpdateHandlers();
    });
  }

  resolveHasUpdateHandlers() {
    const handlers = this.hasUpdateHandlers;
    this.hasUpdateHandlers = [];

    for (const handler of handlers) {
      handler();
    }
  }

  async hasUpdate() {
    // await until a new message have been received, or the socket is closed
    await new Promise((resolve) => {
      this.hasUpdateHandlers.push(() => resolve(null));
    });
  }

  on(type: 'error' | 'close' | 'message' | 'open', handler: (event: any) => void) {
    this.ws.addEventListener(type, handler);
  }

  off(type: 'error' | 'close' | 'message' | 'open', handler: (event: any) => void) {
    this.ws.removeEventListener(type, handler);
  }

  close() {
    this.ws.close();
  }
}

export class StreamInOut<Request, Response> {
  public socket: WebSocketConnection;
  private buffer: Response[] = [];

  constructor(url: string, headers?: Record<string, string>) {
    this.socket = new WebSocketConnection(url, headers);
    this.socket.on('message', (event: any) => {
      this.buffer.push(JSON.parse(event.data));
      this.socket.resolveHasUpdateHandlers();
    });
  }

  close() {
    this.socket.close();
  }

  async send(msg: Request) {
    if (this.socket.ws.readyState === WebSocket.CONNECTING) {
      // await that the socket is opened
      await new Promise((resolve) => {
        this.socket.ws.addEventListener('open', resolve, { once: true });
      });
    }

    return this.socket.ws.send(JSON.stringify(msg));
  }

  async next(): Promise<Response | undefined> {
    for await (const next of this) return next;
    return undefined;
  }

  async *[Symbol.asyncIterator](): AsyncGenerator<Response, undefined, void> {
    while (true) {
      if (this.buffer.length > 0) {
        yield this.buffer.shift() as Response;
      } else {
        if (this.socket.ws.readyState === WebSocket.CLOSED) return;
        await this.socket.hasUpdate();
      }
    }
  }
}

export class StreamIn<Response> {
  public socket: WebSocketConnection;
  private buffer: Response[] = [];

  constructor(url: string, headers?: Record<string, string>) {
    this.socket = new WebSocketConnection(url, headers);
    this.socket.on('message', (event: any) => {
      this.buffer.push(JSON.parse(event.data));
      this.socket.resolveHasUpdateHandlers();
    });
  }

  close() {
    this.socket.close();
  }

  async next(): Promise<Response | undefined> {
    for await (const next of this) return next;
    return undefined;
  }

  async *[Symbol.asyncIterator](): AsyncGenerator<Response, undefined, void> {
    while (true) {
      if (this.buffer.length > 0) {
        yield this.buffer.shift() as Response;
      } else {
        if (this.socket.ws.readyState === WebSocket.CLOSED) return;
        await this.socket.hasUpdate();
      }
    }
  }
}

export class StreamOut<Request, Response> {
  public socket: WebSocketConnection;
  private responseValue: Promise<Response>;

  constructor(url: string, headers?: Record<string, string>) {
    let responseResolver: (_: any) => void;
    this.responseValue = new Promise((resolve) => (responseResolver = resolve));

    this.socket = new WebSocketConnection(url, headers);
    this.socket.on('message', (event: any) => {
      responseResolver(JSON.parse(event.data));
    });
  }

  async response(): Promise<Response> {
    return this.responseValue;
  }

  close() {
    this.socket.close();
  }

  async send(msg: Request) {
    if (this.socket.ws.readyState === WebSocket.CONNECTING) {
      // await that the socket is opened
      await new Promise((resolve) => {
        this.socket.ws.addEventListener('open', resolve, { once: true });
      });
    }

    return this.socket.ws.send(JSON.stringify(msg));
  }
}
// CallParameters is the type of the parameters to a method call, but require headers to be a Record type
type CallParameters = Omit<RequestInit, 'method' | 'body' | 'headers'> & {
  /** Headers to be sent with the request */
  headers?: Record<string, string>;

  /** Query parameters to be sent with the request */
  query?: Record<string, string | string[]>;
};

// AuthDataGenerator is a function that returns a new instance of the authentication data required by this API
export type AuthDataGenerator = () => string | Promise<string | undefined> | undefined;

// A fetcher is the prototype for the inbuilt Fetch function
export type Fetcher = typeof fetch;

const boundFetch = fetch.bind(this);

class BaseClient {
  readonly baseURL: string;
  readonly fetcher: Fetcher;
  readonly headers: Record<string, string>;
  readonly requestInit: Omit<RequestInit, 'headers'> & { headers?: Record<string, string> };
  readonly authGenerator?: AuthDataGenerator;

  constructor(baseURL: string, options: ClientOptions) {
    this.baseURL = baseURL;
    this.headers = {};

    // Add User-Agent header if the script is running in the server
    // because browsers do not allow setting User-Agent headers to requests
    if (typeof window === 'undefined') {
      this.headers['User-Agent'] = 'quiqup-platform-ht42-Generated-TS-Client (Encore/v1.46.7)';
    }

    this.requestInit = options.requestInit ?? {};

    // Setup what fetch function we'll be using in the base client
    if (options.fetcher !== undefined) {
      this.fetcher = options.fetcher;
    } else {
      this.fetcher = boundFetch;
    }

    // Setup an authentication data generator using the auth data token option
    if (options.auth !== undefined) {
      const auth = options.auth;
      if (typeof auth === 'function') {
        this.authGenerator = auth;
      } else {
        this.authGenerator = () => auth;
      }
    }
  }

  async getAuthData(): Promise<CallParameters | undefined> {
    let authData: string | undefined;

    // If authorization data generator is present, call it and add the returned data to the request
    if (this.authGenerator) {
      const mayBePromise = this.authGenerator();
      if (mayBePromise instanceof Promise) {
        authData = await mayBePromise;
      } else {
        authData = mayBePromise;
      }
    }

    if (authData) {
      const data: CallParameters = {};

      data.headers = {};
      data.headers['Authorization'] = 'Bearer ' + authData;

      return data;
    }

    return undefined;
  }

  // createStreamInOut sets up a stream to a streaming API endpoint.
  async createStreamInOut<Request, Response>(
    path: string,
    params?: CallParameters
  ): Promise<StreamInOut<Request, Response>> {
    let { query, headers } = params ?? {};

    // Fetch auth data if there is any
    const authData = await this.getAuthData();

    // If we now have authentication data, add it to the request
    if (authData) {
      if (authData.query) {
        query = { ...query, ...authData.query };
      }
      if (authData.headers) {
        headers = { ...headers, ...authData.headers };
      }
    }

    const queryString = query ? '?' + encodeQuery(query) : '';
    return new StreamInOut(this.baseURL + path + queryString, headers);
  }

  // createStreamIn sets up a stream to a streaming API endpoint.
  async createStreamIn<Response>(path: string, params?: CallParameters): Promise<StreamIn<Response>> {
    let { query, headers } = params ?? {};

    // Fetch auth data if there is any
    const authData = await this.getAuthData();

    // If we now have authentication data, add it to the request
    if (authData) {
      if (authData.query) {
        query = { ...query, ...authData.query };
      }
      if (authData.headers) {
        headers = { ...headers, ...authData.headers };
      }
    }

    const queryString = query ? '?' + encodeQuery(query) : '';
    return new StreamIn(this.baseURL + path + queryString, headers);
  }

  // createStreamOut sets up a stream to a streaming API endpoint.
  async createStreamOut<Request, Response>(
    path: string,
    params?: CallParameters
  ): Promise<StreamOut<Request, Response>> {
    let { query, headers } = params ?? {};

    // Fetch auth data if there is any
    const authData = await this.getAuthData();

    // If we now have authentication data, add it to the request
    if (authData) {
      if (authData.query) {
        query = { ...query, ...authData.query };
      }
      if (authData.headers) {
        headers = { ...headers, ...authData.headers };
      }
    }

    const queryString = query ? '?' + encodeQuery(query) : '';
    return new StreamOut(this.baseURL + path + queryString, headers);
  }

  // callTypedAPI makes an API call, defaulting content type to "application/json"
  public async callTypedAPI(method: string, path: string, body?: BodyInit, params?: CallParameters): Promise<Response> {
    return this.callAPI(method, path, body, {
      ...params,
      headers: { 'Content-Type': 'application/json', ...params?.headers },
    });
  }

  // callAPI is used by each generated API method to actually make the request
  public async callAPI(method: string, path: string, body?: BodyInit, params?: CallParameters): Promise<Response> {
    let { query, headers, ...rest } = params ?? {};
    const init = {
      ...this.requestInit,
      ...rest,
      method,
      body: body ?? null,
    };

    // Merge our headers with any predefined headers
    init.headers = { ...this.headers, ...init.headers, ...headers };

    // Fetch auth data if there is any
    const authData = await this.getAuthData();

    // If we now have authentication data, add it to the request
    if (authData) {
      if (authData.query) {
        query = { ...query, ...authData.query };
      }
      if (authData.headers) {
        init.headers = { ...init.headers, ...authData.headers };
      }
    }

    // Make the actual request
    const queryString = query ? '?' + encodeQuery(query) : '';
    const response = await this.fetcher(this.baseURL + path + queryString, init);

    // handle any error responses
    if (!response.ok) {
      // try and get the error message from the response body
      let body: APIErrorResponse = { code: ErrCode.Unknown, message: `request failed: status ${response.status}` };

      // if we can get the structured error we should, otherwise give a best effort
      try {
        const text = await response.text();

        try {
          const jsonBody = JSON.parse(text);
          if (isAPIErrorResponse(jsonBody)) {
            body = jsonBody;
          } else {
            body.message += ': ' + JSON.stringify(jsonBody);
          }
        } catch {
          body.message += ': ' + text;
        }
      } catch (e) {
        // otherwise we just append the text to the error message
        body.message += ': ' + String(e);
      }

      throw new APIError(response.status, body);
    }

    return response;
  }
}

/**
 * APIErrorDetails represents the response from an Encore API in the case of an error
 */
interface APIErrorResponse {
  code: ErrCode;
  message: string;
  details?: any;
}

function isAPIErrorResponse(err: any): err is APIErrorResponse {
  return (
    err !== undefined &&
    err !== null &&
    isErrCode(err.code) &&
    typeof err.message === 'string' &&
    (err.details === undefined || err.details === null || typeof err.details === 'object')
  );
}

function isErrCode(code: any): code is ErrCode {
  return code !== undefined && Object.values(ErrCode).includes(code);
}

/**
 * APIError represents a structured error as returned from an Encore application.
 */
export class APIError extends Error {
  /**
   * The HTTP status code associated with the error.
   */
  public readonly status: number;

  /**
   * The Encore error code
   */
  public readonly code: ErrCode;

  /**
   * The error details
   */
  public readonly details?: any;

  constructor(status: number, response: APIErrorResponse) {
    // extending errors causes issues after you construct them, unless you apply the following fixes
    super(response.message);

    // set error name as constructor name, make it not enumerable to keep native Error behavior
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/new.target#new.target_in_constructors
    Object.defineProperty(this, 'name', {
      value: 'APIError',
      enumerable: false,
      configurable: true,
    });

    // fix the prototype chain
    if ((Object as any).setPrototypeOf == undefined) {
      (this as any).__proto__ = APIError.prototype;
    } else {
      Object.setPrototypeOf(this, APIError.prototype);
    }

    // capture a stack trace
    if ((Error as any).captureStackTrace !== undefined) {
      (Error as any).captureStackTrace(this, this.constructor);
    }

    this.status = status;
    this.code = response.code;
    this.details = response.details;
  }
}

/**
 * Typeguard allowing use of an APIError's fields'
 */
export function isAPIError(err: any): err is APIError {
  return err instanceof APIError;
}

export enum ErrCode {
  /**
   * OK indicates the operation was successful.
   */
  OK = 'ok',

  /**
   * Canceled indicates the operation was canceled (typically by the caller).
   *
   * Encore will generate this error code when cancellation is requested.
   */
  Canceled = 'canceled',

  /**
   * Unknown error. An example of where this error may be returned is
   * if a Status value received from another address space belongs to
   * an error-space that is not known in this address space. Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * Encore will generate this error code in the above two mentioned cases.
   */
  Unknown = 'unknown',

  /**
   * InvalidArgument indicates client specified an invalid argument.
   * Note that this differs from FailedPrecondition. It indicates arguments
   * that are problematic regardless of the state of the system
   * (e.g., a malformed file name).
   *
   * This error code will not be generated by the gRPC framework.
   */
  InvalidArgument = 'invalid_argument',

  /**
   * DeadlineExceeded means operation expired before completion.
   * For operations that change the state of the system, this error may be
   * returned even if the operation has completed successfully. For
   * example, a successful response from a server could have been delayed
   * long enough for the deadline to expire.
   *
   * The gRPC framework will generate this error code when the deadline is
   * exceeded.
   */
  DeadlineExceeded = 'deadline_exceeded',

  /**
   * NotFound means some requested entity (e.g., file or directory) was
   * not found.
   *
   * This error code will not be generated by the gRPC framework.
   */
  NotFound = 'not_found',

  /**
   * AlreadyExists means an attempt to create an entity failed because one
   * already exists.
   *
   * This error code will not be generated by the gRPC framework.
   */
  AlreadyExists = 'already_exists',

  /**
   * PermissionDenied indicates the caller does not have permission to
   * execute the specified operation. It must not be used for rejections
   * caused by exhausting some resource (use ResourceExhausted
   * instead for those errors). It must not be
   * used if the caller cannot be identified (use Unauthenticated
   * instead for those errors).
   *
   * This error code will not be generated by the gRPC core framework,
   * but expect authentication middleware to use it.
   */
  PermissionDenied = 'permission_denied',

  /**
   * ResourceExhausted indicates some resource has been exhausted, perhaps
   * a per-user quota, or perhaps the entire file system is out of space.
   *
   * This error code will be generated by the gRPC framework in
   * out-of-memory and server overload situations, or when a message is
   * larger than the configured maximum size.
   */
  ResourceExhausted = 'resource_exhausted',

  /**
   * FailedPrecondition indicates operation was rejected because the
   * system is not in a state required for the operation's execution.
   * For example, directory to be deleted may be non-empty, an rmdir
   * operation is applied to a non-directory, etc.
   *
   * A litmus test that may help a service implementor in deciding
   * between FailedPrecondition, Aborted, and Unavailable:
   *  (a) Use Unavailable if the client can retry just the failing call.
   *  (b) Use Aborted if the client should retry at a higher-level
   *      (e.g., restarting a read-modify-write sequence).
   *  (c) Use FailedPrecondition if the client should not retry until
   *      the system state has been explicitly fixed. E.g., if an "rmdir"
   *      fails because the directory is non-empty, FailedPrecondition
   *      should be returned since the client should not retry unless
   *      they have first fixed up the directory by deleting files from it.
   *  (d) Use FailedPrecondition if the client performs conditional
   *      REST Get/Update/Delete on a resource and the resource on the
   *      server does not match the condition. E.g., conflicting
   *      read-modify-write on the same resource.
   *
   * This error code will not be generated by the gRPC framework.
   */
  FailedPrecondition = 'failed_precondition',

  /**
   * Aborted indicates the operation was aborted, typically due to a
   * concurrency issue like sequencer check failures, transaction aborts,
   * etc.
   *
   * See litmus test above for deciding between FailedPrecondition,
   * Aborted, and Unavailable.
   */
  Aborted = 'aborted',

  /**
   * OutOfRange means operation was attempted past the valid range.
   * E.g., seeking or reading past end of file.
   *
   * Unlike InvalidArgument, this error indicates a problem that may
   * be fixed if the system state changes. For example, a 32-bit file
   * system will generate InvalidArgument if asked to read at an
   * offset that is not in the range [0,2^32-1], but it will generate
   * OutOfRange if asked to read from an offset past the current
   * file size.
   *
   * There is a fair bit of overlap between FailedPrecondition and
   * OutOfRange. We recommend using OutOfRange (the more specific
   * error) when it applies so that callers who are iterating through
   * a space can easily look for an OutOfRange error to detect when
   * they are done.
   *
   * This error code will not be generated by the gRPC framework.
   */
  OutOfRange = 'out_of_range',

  /**
   * Unimplemented indicates operation is not implemented or not
   * supported/enabled in this service.
   *
   * This error code will be generated by the gRPC framework. Most
   * commonly, you will see this error code when a method implementation
   * is missing on the server. It can also be generated for unknown
   * compression algorithms or a disagreement as to whether an RPC should
   * be streaming.
   */
  Unimplemented = 'unimplemented',

  /**
   * Internal errors. Means some invariants expected by underlying
   * system has been broken. If you see one of these errors,
   * something is very broken.
   *
   * This error code will be generated by the gRPC framework in several
   * internal error conditions.
   */
  Internal = 'internal',

  /**
   * Unavailable indicates the service is currently unavailable.
   * This is a most likely a transient condition and may be corrected
   * by retrying with a backoff. Note that it is not always safe to retry
   * non-idempotent operations.
   *
   * See litmus test above for deciding between FailedPrecondition,
   * Aborted, and Unavailable.
   *
   * This error code will be generated by the gRPC framework during
   * abrupt shutdown of a server process or network connection.
   */
  Unavailable = 'unavailable',

  /**
   * DataLoss indicates unrecoverable data loss or corruption.
   *
   * This error code will not be generated by the gRPC framework.
   */
  DataLoss = 'data_loss',

  /**
   * Unauthenticated indicates the request does not have valid
   * authentication credentials for the operation.
   *
   * The gRPC framework will generate this error code when the
   * authentication metadata is invalid or a Credentials callback fails,
   * but also expect authentication middleware to generate it.
   */
  Unauthenticated = 'unauthenticated',
}
