import { version } from '../package.json';
interface EnvKeys {
  API_URL?: string;
  [key: string]: string | undefined;
}

const ENV_JSON = '/env.json';
const ENV_VAR_ACCEPTED_VARS: string[] = [
  'ALLOW_TEST_MOBILE_NUMBER',
  'API_VERSION',
  'APP_VERSION',
  'API_BASE_URL',
  'OSRM_URL',
  'GENERATE_MATCH_URL',
  'SOCKET_URL',
  'QU_ERY_GRAPHQL',
  'ADDRESS_BOOK_GRAPHQL',
  'API_APP_PLATFORM',
  'API_APP_NAME',
  'SITE_ENV',
  'TRACK_APP_URL',
  'GOOGLE_API_KEY',
  'GOOGLE_GEOCODING_API_KEY',
  'ANALYTICS_TOKEN',
  'API_GATEWAY',
  'ORDERS_CORE_API_URL',
  'ORDERS_CORE_API_WS',
  'EX_CORE_API_URL',
  'INVOICER_API',
  'METABASE_URL',
  'METABASE_ALSHAYAA_DASHBOARD_ID',
  'METABASE_DEFAULT_DASHBOARD_ID',
  'ZOHO_BOOKS_API',
  'ZOHO_ORG_ID',
  'SITE_URL',
  'PLATFORM_API',
  'FINANCE_API',
  'LOAD_STRIPE',
  'GOOGLE_API_URL',
];

const getEnvVars = async (): Promise<EnvKeys> => {
  if (process.env.NODE_ENV === 'production') {
    const resp = await fetch(ENV_JSON);
    return resp.json();
  }

  return new Promise((resolve) => resolve(process.env));
};

export const loadEnv = async (): Promise<EnvKeys> => {
  const env = await getEnvVars();
  const newVars: EnvKeys = {};
  ENV_VAR_ACCEPTED_VARS.forEach((key) => {
    const val = env[key] ?? env[`REACT_APP_${key}`];
    if (val) {
      newVars[key] = env[key] ?? env[`REACT_APP_${key}`];
    }
  });

  newVars.APP_VERSION = version;
  return newVars;
};

export default { loadEnv };
